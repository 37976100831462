/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import moment from 'moment';

import Loading from '@components/Loading';
import DeleteConfirmationModal from '@components/Modals/DeleteConfirmationModal';
import NotFound from '@components/Routing/NotFound';
import { titleTemplateParams } from '@components/Title/util';

import ContactInformation from './Contacts/View';
import GeneralInfo from './GeneralInfo/index';
import Meetings from './Meetings/MeetingsList';
import ClubOfficers from './Officers';
import Sponsorships from './Sponsorships';

import { isClubRotaractSatellite } from '@use-cases/clubs/helpers/isClubRotaractSatellite';
import { useErrorHandling } from '@use-cases/notifications';

import { isEditLevel } from '@utils/access-helpers';
import { getClubDestinationURL } from '@utils/getClubDestination';
// import { isRotaryClub } from '@utils/isRotaryClub';
import { localizedNavigate } from '@utils/localized-navigate';

import Divider from '@domui-components/Divider';
import { DomuiError } from '@domui-domain/type';
import { useFetchClubPermission } from '@domui-hooks/iam/useFetchClubPermission';
import { useDeleteClubMeeting } from '@domui-hooks/useDeleteClubMeeting';
import { useFetchClubLeadership } from '@domui-hooks/useFetchClubLeadership';
import { useFetchOrganization } from '@domui-hooks/useFetchOrganization';
import { getClubDistrictFromLocal } from '@domui-sitecore/utilities/helper-functions';
import { getOfficersPagePath, isClubRotaract } from '@domui-use-cases/clubs';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

type clubLeadershipBody = {
  domOrgId: string;
  startDate: string;
  endDate: string;
  pageNumber: number;
  pageSize: number;
};

const ClubDetails: React.FC<RouteComponentProps & {
  clubId: string;
  domOrgId: string;
}> = ({ clubId, domOrgId }) => {
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);
  const [meetingError, setMeetingError] = useState<DomuiError>({});

  const individualData = getClubDistrictFromLocal();
  const requestorId = individualData?.individualInfo?.id;

  useErrorHandling(
    meetingError?.description
      ? t('delete-meeting.error', 'An error occurred while delete meeting.')
      : '',
    !!meetingError,
    'delete-meeting.error'
  );

  const {
    data: getOrganizationData,
    loading: getOrganizationLoading,
    // error: getOrganizationError,
    fetchOrganization,
  } = useFetchOrganization();
  const {
    data: iamClubData,
    loading: iamClubLOADING,
    // error: IAMError,
    fetchClubPermission,
  } = useFetchClubPermission();
  const getRotaryYear = () => {
    return (moment().format('MM-DD') < '07-01'
      ? moment().year()
      : moment().year() + 1
    ).toString();
  };

  const { deleteClubMeeting } = useDeleteClubMeeting();

  const canEditClubInfo = isEditLevel(iamClubData?.club);
  const canEditOfficers = isEditLevel(iamClubData?.leaderships);
  const payload = {
    data: [
      {
        target: `/clubs/${clubId}/membership`,
      },
      {
        target: `/clubs/${clubId}/leadership`,
        arguments: {
          targetTermYear: getRotaryYear(),
        },
      },
      {
        target: `/clubs/${clubId}`,
      },
    ],
  };
  useEffect(() => {
    fetchClubPermission(requestorId, payload);
  }, [requestorId]);

  const {
    data: getClubLeadership,
    loading: getClubLeadershipLoading,
    // error: getClubLeadershipError,
    fetchClubLeadership,
  } = useFetchClubLeadership();

  const requestBody: clubLeadershipBody = {
    domOrgId,
    startDate: '2024-July-01',
    endDate: '2025-June-30',
    pageNumber: 1,
    pageSize: 10,
  };

  useEffect(() => {
    if (domOrgId) {
      fetchClubLeadership(requestBody);
      fetchOrganization(domOrgId);
    }
  }, [domOrgId]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [meetingToDelete, setMeetingToDelete] = useState<number | null>(null);

  if (
    !getClubLeadership ||
    getClubLeadershipLoading ||
    !getOrganizationData ||
    getOrganizationLoading ||
    iamClubLOADING
  ) {
    return <Loading />;
  }

  const isRotaractClub = isClubRotaract('Rotary_Club');
  const isRotaractClubType =
    isRotaractClub || isClubRotaractSatellite('Rotary_Club');

  if (!getOrganizationData?.riClubId) {
    return <NotFound />;
  }

  const {
    riClubId,
    district,
    charteredDate,
    type,
    addresses,
    meetings,
    clubSponsorOf,
    clubSponsoredBy,
  } = getOrganizationData;

  // const isRotaryClubType = isRotaryClub(type);

  const handleDeleteMeeting = async (meetingIndex: number | null) => {
    if (meetingIndex != null) {
      const meeting = meetings[Number(meetingIndex)];
      try {
        const response = await deleteClubMeeting(meeting?.meetingId);
        if (response) {
          await fetchOrganization(domOrgId);
        }
      } catch (error) {
        setMeetingError(error as DomuiError);
      }
    }
  };

  const handleGoToOfficers = () =>
    localizedNavigate(getOfficersPagePath(domOrgId), {
      state: {
        isFromDetailsPage: true,
      },
    });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function physicalLocation(addresses: any) {
    return addresses.find(
      (address: { type: string }) => address.type === 'Physical Location'
    );
  }

  const { leadership } = getClubLeadership;

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.club-details-section',
          '{{prefix}} Club details {{suffix}}',
          { prefix, suffix }
        )}
      />
      <div className="flex flex-col mt-8 desktop:flex-row">
        <div className="desktop:w-3/5 desktop:px-6 desktop:mr-15 w-full">
          <GeneralInfo
            // eslint-disable-next-line react/jsx-boolean-value
            isUserHomeDistrict={true}
            districtId={district?.districtDisId}
            district={district?.riDistrictId}
            physicalLocation={physicalLocation(addresses)}
            charteredDate={charteredDate}
            riClubId={riClubId}
            clubType={type}
          />
          <Divider />
          <ContactInformation
            emails={getOrganizationData?.emails || []}
            phones={getOrganizationData?.phones || []}
            // primaryFax={primaryFax}
            websites={getOrganizationData?.websites || null}
            officialLanguage={getOrganizationData?.officialLanguage || null}
            addresses={getOrganizationData?.addresses || []}
            isEditable={canEditClubInfo}
            //  isUserLoggedIn={user?.isLoggedIn}
            editContactInformationLink={`/domui/club/${clubId}/edit-contact`}
            // countries={countriesList?.countries || []}
            //  individualOperationAccess={data.club.operationsAccess.club}
          />

          {!!(clubSponsoredBy?.length || clubSponsorOf?.length) && (
            <>
              <Divider />
              <Sponsorships
                parentClubRelationships={clubSponsoredBy}
                childClubRelationships={clubSponsorOf}
                isRotaractClubType={isRotaractClubType}
              />
            </>
          )}
          {/* {(isRotaractClub || isRotaryClubType) && ( */}
          {/* <> */}
          {!!leadership.length && (
            <>
              <Divider />
              <ClubOfficers
                leaders={leadership}
                isEditable={canEditOfficers}
                handleGoToOfficers={handleGoToOfficers}
                profilePreviousLinkPath={getClubDestinationURL(
                  domOrgId,
                  'details'
                )}
              />
              {/* </> */}
              {/* )} */}
              {/* {isClubManagementSystemsSectionShown && (
                <>
                  <Divider />
                  <ClubManagementSystems
                    isEditable={canEditClubInfo}
                    vendors={getVendorsList(vendors)}
                    editManagementSystemsLink={`/club/${clubId}/edit-management-systems`}
                  />
                </>
              )} */}
            </>
          )}
        </div>
        <div className="desktop:w-2/5 w-full">
          <Meetings
            editMeetingLink={(index: number) =>
              `/domui/club/${clubId}/edit-meeting/${index}`
            }
            isEditable={canEditClubInfo}
            addMeetingLink={`/domui/club/${clubId}/add-meeting`}
            handleDelete={meetingIndex => setMeetingToDelete(meetingIndex)}
            meetings={meetings || []}
          />
        </div>
      </div>
      <DeleteConfirmationModal
        isOpen={meetingToDelete !== null}
        onConfirm={() => {
          handleDeleteMeeting(meetingToDelete);
          setMeetingToDelete(null);
        }}
        closeModal={() => setMeetingToDelete(null)}
        title={t(
          'club-details.meetings.confirm-deletion.title',
          'Are you sure you want to delete this meeting?'
        )}
      />
    </>
  );
};

export default ClubDetails;
